// import { Link } from "gatsby"
import React from "react"

export default function LinkSection(props) {
  const wiki = props.wiki
  // console.log("wiki", wiki)
  //   return (
  // <div>
  // {
  /* <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"> */
  // }
  return (
    <section className="section services-section" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title">
              <h2>Links</h2>
              {/* <p>See below some links on the Internet</p> */}
            </div>
          </div>
        </div>
        <div className="row">
          {/* <!-- feaure box --> */}
          <div className="col-sm-6 col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-desktop"></i>
              </div>
              <div className="feature-content">
                <h5>On Wikipedia</h5>
                <p>
                  {wiki.firstText}
                  <a href={wiki.wikiLink} target="_blank" rel="noreferrer">
                    &nbsp;Read more..
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* <!-- / -->
            <!-- feaure box --> */}
          {/* <div className="col-sm-6 col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-user"></i>
              </div>
              <div className="feature-content">
                <h5>Different Layout</h5>
                <p>
                  I design and develop services for customers of all sizes,
                  specializing in creating stylish, modern websites.
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- / -->
            <!-- feaure box --> */}
          {/* <div className="col-sm-6 col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-comment"></i>
              </div>
              <div className="feature-content">
                <h5>Make it Simple</h5>
                <p>
                  I design and develop services for customers of all sizes,
                  specializing in creating stylish, modern websites.
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- / -->
            <!-- feaure box --> */}
          {/* <div className="col-sm-6 col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-image"></i>
              </div>
              <div className="feature-content">
                <h5>Responsiveness</h5>
                <p>
                  I design and develop services for customers of all sizes,
                  specializing in creating stylish, modern websites.
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- / -->
            <!-- feaure box --> */}
          {/* <div className="col-sm-6 col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-th"></i>
              </div>
              <div className="feature-content">
                <h5>Testing for Perfection</h5>
                <p>
                  I design and develop services for customers of all sizes,
                  specializing in creating stylish, modern websites.
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- / --> */}
          {/* <!-- feaure box --> */}
          {/* <div className="col-sm-6 col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-cog"></i>
              </div>
              <div className="feature-content">
                <h5>Advanced Options</h5>
                <p>
                  I design and develop services for customers of all sizes,
                  specializing in creating stylish, modern websites.
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- / --> */}
        </div>
      </div>
    </section>

    // </div>
  )
}
