import React from "react"

export default function YoutubeChannelTile({ channel }) {
  //   console.log("YoutubeChannelTile data", channel)
  //   console.log(
  //     `link to youtube channel ${channel.channelTitle} is ${channel.link}`
  //   )
  return (
    <div className="col-md-4">
      <span className="fa-stack fa-4x">
        <i className="fas fa-circle fa-stack-2x text-primary"></i>
        <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
      </span>
      <h4 className="my-3">{channel.channelTitle}</h4>
      <p className="text-muted">{channel.description}</p>
      <h5 className="my-3">
        <a href={channel.link} target="_blank" rel="noreferrer">
          <img src={channel.thumbnails.medium.url} alt={channel.channelTitle} />
        </a>
      </h5>
    </div>
  )
}
