import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
// import Img from 'gatsby-image'
import Seo from "../components/seo"
import Navigation from "../components/navigation"
import Masthead from "../components/masthead"
import GeneralTile from "../components/generaltile"
import TilesSection from "../components/tilessection"
import Services from "../components/services"
import LinkSection from "../components/linksection"
const { makeSlug } = require("../../src/utils/cbsanitizer")

const {
  getFileImageNode,
  getImageForName,
} = require("../components/imageutils/fileImageNode")

export default function BrandDetails({ data }) {
  // const {html} = data.markdownRemark;
  // const {title,stack,featuredImg} = data.markdownRemark.frontmatter;

  // console.log("data in BrandDetails", data)

  const nodes = data.allTypesConvertedJson.nodes
  const imageNodes = data.allFile.nodes

  const homepage = data.allTypesWikiJson.nodes[0].homepage
  const wiki = data.allTypesWikiJson.nodes[0].wiki
  // console.log(`wiki is`, wiki)
  //   const ytnodes = data.allBrandYoutubeJson.nodes

  //   console.log("ytnodes", ytnodes)

  const [filterType, setFilterType] = useState("")
  if (nodes.length !== 1) {
    console.log("No brands in data??")
    return
  }

  const brand = nodes[0]

  // console.log("data in brand-details is:", data)

  const brandWithYT = data.allBrandYoutubeJson.nodes[0]
  const allFileImageNodes = data.allFile.nodes

  // console.log("brandWithYT", brandWithYT)

  return (
    <Layout>
      <Seo title={brand.name} />
      <Navigation />
      <Masthead title={brand.name} homepage={homepage} />

      <Services
        brandName={brand.name}
        brandWithYT={brandWithYT}
        titlePostFix="on Youtube"
      />

      <LinkSection wiki={wiki} />

      <TilesSection title={brand.name}>
        <div className="row">
          <input
            value={filterType}
            id="filterType"
            className="form-control"
            type="text"
            placeholder="Filter types"
            onChange={event => setFilterType(event.target.value)}
          />
        </div>

        <div className="row">
          {brand.types
            .filter(type => {
              return (
                type.typeName
                  .toLowerCase()
                  .includes(filterType.toLowerCase()) || filterType === ""
              )
            })
            .map((type, i) => {
              const brandTechicalName = brand.name
                .toLowerCase()
                .replace(" ", "-")
              const typeTechnicalName = type.typeName
                .toLowerCase()
                .replace(" ", "-")
              const brandTypeTechicalName =
                brandTechicalName + "_" + typeTechnicalName

              const urlValue =
                "/" + makeSlug(brand.name) + "/" + typeTechnicalName

              const fileImageNode = getFileImageNode(
                brandTypeTechicalName,
                allFileImageNodes
              )

              const node = {
                id: type.id,
                fileImageNode: fileImageNode,
                name: type.typeName,
                imgFluid: getImageForName(
                  brand.name,
                  type.typeName,
                  imageNodes
                ),
                imgName:
                  "/img/common/" +
                  (brand.name + "_" + type.typeName).toLowerCase() +
                  ".jpg",
                url: urlValue,
              }

              return (
                <GeneralTile node={node} key={node.id} />
                // <li key={tname}>{tname}</li>
              )
            })}
        </div>
      </TilesSection>
    </Layout>
  )
}

//, $imageName: String
export const query = graphql`
  query BrandDetailsQuery($name: String, $nameLowerCase: String) {
    allTypesConvertedJson(filter: { name: { eq: $name } }) {
      nodes {
        id
        name
        disabled
        types {
          id
          typeName
        }
      }
    }

    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      nodes {
        childImageSharp {
          fluid {
            originalName
          }
          gatsbyImageData(
            placeholder: BLURRED
            quality: 70
            blurredOptions: { width: 100 }
            # aspectRatio: 1.5
            transformOptions: { cropFocus: CENTER, fit: COVER }
            # width: 900
            # height: 600
          )
        }
      }
    }

    allTypesWikiJson(filter: { name: { eq: $nameLowerCase } }) {
      nodes {
        name
        homepage
        id
        wiki {
          wikiLink
          firstText
        }
      }
    }

    allBrandYoutubeJson(
      filter: {
        yt: { elemMatch: { kind: { eq: "youtube#channel" } } }
        name: { eq: $nameLowerCase }
      }
    ) {
      nodes {
        name
        yt {
          id
          description
          kind
          link
          title
          channelTitle
          channelId
          thumbnails {
            medium {
              url
            }
          }
        }
      }
    }
  }
`
