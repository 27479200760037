import React from "react"
// import VideoElement from "./videoelement"
import YoutubeChannelTile from "./YoutubeChannelTile"

export default function Services({ brandName, brandWithYT, titlePostFix }) {
  // console.log(`Brand name in Serives ${brandName}`)
  // console.log("brandWithYT is", brandWithYT)

  const items = []
  if (brandWithYT && brandWithYT.yt) {
    brandWithYT.yt.forEach(e => {
      // const embedLink = "https://www.youtube.com/embed/" + e.id
      // console.log("embedLink to use:", embedLink)
      if (e.kind === "youtube#channel") {
        items.push(<YoutubeChannelTile key={e.id} channel={e} />)
        // items.push(<VideoElement embedLink={embedLink} title={e.title} />)
      }
    })
  }
  if (items.length === 0) return null
  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">
            {brandName} {titlePostFix}
          </h2>
          <h3 className="section-subheading text-muted">
            {/* Lorem ipsum dolor sit amet consectetur. */}
          </h3>
        </div>

        <div className="row text-center">
          {items}

          {/* <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">11111111111111111</h4>
            <p className="text-muted">...</p>
          </div> */}

          {/* <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">2222222222222</h4>
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              maxime quam architecto quo inventore harum ex magni, dicta
              impedit.
            </p>
          </div> */}

          {/* <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">333333333333333</h4>
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              maxime quam architecto quo inventore harum ex magni, dicta
              impedit.
            </p>
          </div> */}
        </div>
      </div>
    </section>
  )
}
